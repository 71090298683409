


















import { defineComponent, PropType } from '@vue/composition-api'
import { IMaterialType } from '@/interfaces'
import { DropdownMulti } from '@/components/molecules/DropdownMulti'
import { OfferType } from '@/enums'

export default defineComponent({
  name: 'MaterialTypeTags',
  components: { DropdownMulti },
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    returnValue: {
      type: String as PropType<keyof IMaterialType>,
      default: () => 'id',
    },
    aggregatedTags: {
      type: Array as PropType<string[]>,
      default: () => null,
    },
  },
  data() {
    return {
      OfferType,
      isLoading: true,
      allMaterialTypes: [],
    }
  },
  computed: {
    materialTypes: {
      get(): Array<string> {
        if (this.isLoading) {
          return []
        }

        return this.value
      },
      set(materialTypeIds: Array<string>) {
        this.$emit(
          'input',
          this.allMaterialTypes
            .filter((materialType: IMaterialType) => {
              return materialTypeIds.includes(
                materialType[this.returnValue as string]
              )
            })
            .map(
              (materialType: IMaterialType) =>
                materialType[this.returnValue as keyof IMaterialType]
            )
        )
      },
    },
  },
  async created() {
    await this.$store.dispatch('materialType/getMaterialTypes')
    this.allMaterialTypes = await this.$store.getters[
      'materialType/getMaterialTypes'
    ]
    this.isLoading = false
    this.$emit('ready', 'materialTypes')
  },
  methods: {
    change() {
      this.$emit('change')
    },
  },
})
