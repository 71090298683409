

























import {
  defineComponent,
  computed,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'
import useRating from '@/components/common/Rating/composable'
import { RatingCategoriesList } from '@/components/common/Rating/RatingCategoriesList'
import { StarRating } from '@/components/common/Rating/StarRating'
import { RatingPopover } from '@/components/common/Rating/RatingPopover'
import { RatedCategory } from '@/interfaces'

export default defineComponent({
  name: 'SaveRating',
  components: {
    StarRating,
    RatingCategoriesList,
    RatingPopover,
  },
  props: {
    initialRatings: {
      type: Array as PropType<RatedCategory[]>,
      default: () => null,
    },
    avgRating: {
      type: Number as PropType<number>,
      default: () => null,
    },
  },
  setup(props, context) {
    const {
      calculateArithmeticalMean,
      getInitialRating,
      ratingAggregate,
      isLoading,
    } = useRating(context)

    const state = reactive({
      ratings: props.initialRatings || getInitialRating(),
      wasSaved: false,
      rating: props.avgRating,
    })

    const ratings = ref(props.initialRatings || getInitialRating())

    const hasRating = computed(() => !!props.initialRatings || state.wasSaved)
    const isDisabled = computed(() =>
      ratings.value.some((category) => category.rating === 0)
    )

    const isPopoverVisible = ref(false)

    watch(
      () => context.root.$route,
      () => {
        state.wasSaved = false
      },
      { deep: true }
    )

    const onRatingReset = () => {
      if (!hasRating) {
        ratings.value = getInitialRating()
      }
    }

    const onRatingSet = (value: RatedCategory) => {
      ratings.value = ratingAggregate(value)
    }

    const onRatingSave = () => {
      context.emit('ratingSave', ratings.value)

      state.wasSaved = true
      isPopoverVisible.value = false
      state.rating = calculateArithmeticalMean(ratings.value)
    }

    return {
      onRatingReset,
      onRatingSave,
      onRatingSet,
      isDisabled,
      isPopoverVisible,
      hasRating,
      isLoading,
      ...toRefs(state),
    }
  },
})
