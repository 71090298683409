






































import { mapActions, mapGetters } from 'vuex'
import OrganisationTags from '@/components/common/OrganisationTags/OrganisationTags.vue'
import MaterialTypeTags from '@/components/common/MaterialTypeTags.vue'
import { Checkbox } from '@/components/molecules/Checkbox'

export default {
  name: 'TransactionFilter',
  components: {
    OrganisationTags,
    MaterialTypeTags,
    Checkbox,
  },
  inject: ['enums'],
  data() {
    return {
      partnersOrganisationsId: [],
      createdAtPickerOptions: {
        firstDayOfWeek: this.$appConfig.date.firstDayOfWeek,
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
    }),
    ...mapGetters('transaction', {
      filtersFromQuery: 'getFiltersFromQuery',
      queryFromFilters: 'getQueryFromFilters',
    }),
    ...mapGetters('organisation', ['partnersOrganisations']),
    materialTypeIds: {
      get(): string[] {
        return this.filtersFromQuery.materialTypeId || []
      },
      set(value: string[]) {
        this.filtersFromQuery.materialTypeId = value
      },
    },
  },
  async created() {
    await this.fetchFiltersFromQuery(this.$route.query)
    await this.fetchPartnersOrganisations(
      this.$route.params.id || this.user.organisationId
    )
  },
  methods: {
    ...mapActions('transaction', ['fetchFiltersFromQuery']),
    ...mapActions('organisation', ['fetchPartnersOrganisations']),
    async updateQuery() {
      this.organisationId = this.$route.params.id || this.user.organisationId

      await this.$router.push({
        query: this.queryFromFilters,
      })
      await this.fetchFiltersFromQuery(this.$route.query)
    },
  },
}
