


































































































import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue'
import TransactionFilter from '@/components/transaction/TransactionFilter/TransactionFilter.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { fileDownload, formatDate, toUnixTime } from '@/utils/utils'
import { TransactionMixin } from '@/mixins/TransactionMixin'
import { ITransaction } from '@/modules/transaction/resources'
import { SaveRating } from '@/components/common/Rating/SaveRating'
import { OfferContractType, TransactionStatus } from '@/enums'
import { CommonRatingAggregate } from '@/interfaces'
import { PageTitle } from '@/components/atoms/PageTitle'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

interface IDeliveryFromFilters {
  deliveryFrom: [Date, Date]
}

const tableHeaders = [
  { text: 'transaction.status.label', value: 'badge' },
  { text: 'transaction.number', value: 'transactionNumber' },
  { text: 'common.form.created.label', value: 'createdAt' },
  { text: 'transaction.label.deliveryPeriod', value: 'deliveryPeriod' },
  { text: 'transaction.label.seller', value: 'seller' },
  { text: 'transaction.label.seller', value: 'buyer' },
  { text: 'common.materialType', value: 'materialType' },
  // calculation fields
  {
    text: 'common.guaranteedUnitAmount',
    value: 'guaranteedUnitAmount',
    width: 80,
  },
  {
    text: 'common.feePerMinimumLoadingWeight',
    value: 'feePerMinimumLoadingWeight',
    width: 80,
  },
  // globaladmin fields should come here
  { text: 'tradingPartners.table.ratings', value: 'ratings' },
  { text: '', value: 'download' },
]
const tableHeadersGlobalAdmin = [
  ...tableHeaders.slice(0, 9),
  {
    text: 'transaction.invoice',
    value: 'invoiceNumber',
  },
  ...tableHeaders.slice(9),
]

export default {
  name: 'Transactions',
  components: {
    PageTitle,
    TransactionStatusBadge,
    TransactionFilter,
    SaveRating,
    LoadingSpinner,
    LoadMoreButton,
  },
  mixins: [TransactionMixin],
  inject: ['enums'],
  data() {
    return {
      isPageLoading: true,
      organisationId: null,
      currentRow: {},
    }
  },
  computed: {
    ...mapGetters('auth', {
      isGlobalAdmin: 'getIsGlobalAdmin',
      user: 'getUser',
      isDeliveriesAvailable: 'isDeliveriesAvailable',
    }),
    ...mapGetters('transaction', {
      isLoadingNext: 'getIsLoadingNext',
      isLoading: 'getIsLoading',
      tableDataNext: 'getTableDataNext',
      organisation: 'getOrganisation',
      transactions: 'getTransactions',
      filters: 'getFiltersFromQuery',
      isLoadingExport: 'getIsLoadingExport',
    }),
    pageTitle() {
      if (this.organisation?.name && this.$route.params.id) {
        return this.$t('transaction.overviewTitle', {
          name: this.organisation.name,
        })
      }
      return this.$tc('transaction.title', 1)
    },
    tableHeaders() {
      const headers = this.user?.isGlobalAdmin
        ? tableHeadersGlobalAdmin
        : tableHeaders
      return headers.map((header) => ({
        ...header,
        text: header.text ? this.$t(header.text) : '',
        sortable: false,
      }))
    },
  },
  watch: {
    $route: {
      deep: true,
      async handler /* istanbul ignore next */() {
        await this.onOrganisationChanged()
      },
    },
  },
  async mounted() {
    await this.onOrganisationChanged()
  },
  methods: {
    ...mapActions('transaction', [
      'fetchTransactions',
      'fetchOrganisation',
      'fetchCSVExport',
    ]),
    ...mapActions('rating', ['saveRatings']),
    async onOrganisationChanged() {
      this.organisationId = this.$route.params.id || this.user.organisationId
      try {
        await this.fetchOrganisation(this.organisationId)
        await this.fetchTransactions({ organisationId: this.organisationId })
        this.isPageLoading = false
      } catch (e) {
        this.isPageLoading = false
      }
    },
    async generateCSVExport() {
      let dateRange = ''
      const result = await this.fetchCSVExport({
        organisationId: this.organisationId,
      })
      const { deliveryFrom } = this.filters as IDeliveryFromFilters

      const formattedDeliveryFrom = deliveryFrom.map((time) => {
        const unixTimestamp = toUnixTime(time.getTime())
        return formatDate(unixTimestamp)
      })

      if (deliveryFrom && deliveryFrom.length) {
        dateRange = ` - ${formattedDeliveryFrom[0]} - ${formattedDeliveryFrom[1]}`
      }
      const filename = `${this.$t('transaction.export.label')} ${
        this.$appConfig.name
      }${dateRange}.csv`

      fileDownload(filename, result)
    },
    async onRatingSave(
      transaction: ITransaction,
      rating: CommonRatingAggregate[]
    ) {
      await this.saveRatings({ transaction, rating })
    },
    getDeliveryFrom(transaction: ITransaction) {
      return transaction.deliveryFrom
        ? this.dateMonth(transaction.deliveryFrom)
        : ''
    },
    getTotalAmount(transaction: ITransaction) {
      return (
        transaction.deliveryAmount?.totalAmount ||
        (transaction.calculation as any)?.totalAmount ||
        0
      )
    },
    isDeliveryInFuture(transaction: ITransaction): boolean {
      return moment().diff(moment.unix(transaction.deliveryFrom)) < 0
    },
    dateMonth(timestamp: number) {
      return moment.unix(timestamp).format('MMMM YYYY')
    },
    tableRowClassName(item: ITransaction) {
      if (item.offer.contractType === OfferContractType.Recurring) {
        return ['is-clickable', 'type-contract']
      }
      return 'is-clickable'
    },
    isActiveLink(transaction: ITransaction): boolean {
      return [
        TransactionStatus.Open,
        TransactionStatus.AwaitingPrice,
        TransactionStatus.Invoiced,
        TransactionStatus.Free,
      ].includes(transaction.transactionStatus)
    },
    isTransactionCancelled(transaction: ITransaction): boolean {
      return transaction.transactionStatus === TransactionStatus.Cancelled
    },
    onRowClick({ id }: { id: string }, { columnKey }: { columnKey: string }) {
      if (
        ['download', 'ratings'].includes(columnKey) ||
        !this.isDeliveriesAvailable
      ) {
        return
      }

      this.$router.push({
        name: 'transaction',
        params: { id },
      })
    },
  },
}
