


































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { VSelect, VAutocomplete } from 'vuetify/lib'

type ICustomValue = string | ((obj: Record<string, any>) => string)

interface IDropdownItem {
  text?: string | number | object
  value?: string | number | object
  disabled?: boolean
  divider?: boolean
  header?: string
  [key: string]: any
}

export default defineComponent({
  components: {
    VSelect,
    VAutocomplete,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    // "value" is used for internal use of v-model
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<IDropdownItem[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    areChipsClearable: {
      type: Boolean,
      default: true,
    },
    areChipsShortened: {
      type: Boolean,
      default: false,
    },
    customText: {
      type: [String, Function] as PropType<ICustomValue>,
      default: 'text',
    },
    customValue: {
      type: [String, Function] as PropType<ICustomValue>,
      default: 'value',
    },
    selectedItemsToShow: {
      type: Number,
      default: Infinity,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isFilterable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get: () => {
        return props.value
      },
      set: (newValue: string[]) => {
        emit('input', newValue)
      },
    })

    const handleChange = (newValue: string[]) => {
      emit('change', newValue)
    }
    const handleRemoveSelectedItem = (itemToRemove: IDropdownItem) => {
      modelValue.value = modelValue.value.filter(
        (item) => item !== itemToRemove.value
      )
      handleChange(modelValue.value)
    }

    const getChipText = (item: IDropdownItem) => {
      let text = ''
      if (typeof props.customText === 'function') {
        text = props.customText(item)
      } else if (typeof props.customText === 'string') {
        text = item[props.customText]
      }
      if (props.areChipsShortened && text.includes('-')) {
        text = text.split('-')[0].trim()
      }

      return text
    }

    return {
      modelValue,
      handleChange,
      handleRemoveSelectedItem,
      getChipText,
    }
  },
})
