var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"page-container"},[_c('loading-spinner',{attrs:{"is-loading":_vm.isPageLoading}}),_c('page-title',{attrs:{"title":_vm.pageTitle}}),_c('transaction-filter',[_c('div',{staticClass:"holder"},[(_vm.filters.deliveryFrom && _vm.filters.deliveryFrom.length)?_c('v-btn',{staticClass:"generate-csv-export-btn",attrs:{"loading":_vm.isLoadingExport,"color":"primary"},on:{"click":function($event){return _vm.generateCSVExport()}}},[_vm._v(" "+_vm._s(_vm.$t('transaction.export.button'))+" ")]):_vm._e()],1)]),_c('v-data-table',{staticClass:"table",attrs:{"loading":_vm.isLoading,"items":_vm.transactions || [],"headers":_vm.tableHeaders,"item-class":_vm.tableRowClassName,"disable-pagination":"","disable-filtering":"","hide-default-footer":""},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.badge",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-wdio-transaction":item.offer.id}},[_c('transaction-status-badge',{staticClass:"badge",attrs:{"transaction-status":item.transactionStatus}})],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("toUnixTime")(item.createdAt)))+" ")]}},{key:"item.deliveryPeriod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDeliveryFrom(item))+" ")]}},{key:"item.seller",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.seller.organisation.name)+" ")]}},{key:"item.buyer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.buyer.organisation.name)+" ")]}},{key:"item.materialType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.offer.materialType.code)+" ")]}},{key:"item.guaranteedUnitAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalAmount(item))+" "+_vm._s(item.offer.material.unitType)+" ")]}},{key:"item.feePerMinimumLoadingWeight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.calculation.unitFee))+" ")]}},{key:"item.ratings",fn:function(ref){
var item = ref.item;
return [(!_vm.isDeliveryInFuture(item) && !_vm.isTransactionCancelled(item))?_c('save-rating',{key:item.transactionNumber,attrs:{"initial-ratings":item.rating,"avg-rating":item.avgRating},on:{"ratingSave":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onRatingSave.apply(void 0, [ item ].concat( argsArray ))}}}):_vm._e()]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [(_vm.isActiveLink(item))?_c('v-btn',{staticClass:"v-btn__squared-icon",attrs:{"name":item.offer.id,"icon":"","fab":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.downloadTransactionConfirmation(
            item.offer,
            item.transactionNumber,
            item.transactionStatus
          )}}},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1):_vm._e()]}}])}),(_vm.tableDataNext)?_c('load-more-button',{attrs:{"is-loading":_vm.isLoadingNext},on:{"click":function($event){return _vm.fetchTransactions({ organisationId: _vm.organisationId, next: _vm.tableDataNext })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }